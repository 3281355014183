import * as React from "react"
import Header from "../components/header/header";
import Layout from "../components/layout/layout";
import {container, loader} from "../assets/pageStyles/pricing.module.scss";
import {appConfig} from "../assets/config";
import {graphql, navigate} from 'gatsby';
import {useEffect, useRef, useState} from "react";
import Loader from "../components/loader/Loader";

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

const PricingPage = (props) => {
    const iframe = useRef();
    const [loaded, setLoaded] = useState(false)

    if(typeof window !== `undefined`){
    window.addEventListener('message', function(event) {
        if(event?.data?.closeDoxio){
            navigate('/');
        }
    });
    }

    useEffect(() => {
        // setTimeout(() => {
        //     setLoaded(true)
        // }, 3000)
        const signupRoute = appConfig.returnIframeRouteBillingPackages()
        navigate(signupRoute)
    }, [])

    return (
        <Layout location={props?.location?.pathname}>
            <div className={container}>
                {!loaded && <div className={loader}><Loader/></div>}
                <iframe title="Billing select packages" ref={iframe} src={appConfig.returnIframeRouteBillingPackages()} onLoad={() => setTimeout(() => setLoaded(false), 2000)}>
                </iframe>
            </div>
        </Layout>
    )
}

export default PricingPage

export const Head = () => <Header />
